import React, { Component,useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Paper, Container } from '@mui/material';

import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { set } from 'firebase/database';
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
const ERROR_CODE_ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential';
const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

const SignInPage = () => (
  <Container component="main" maxWidth="xs">
    <Paper elevation={3} style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component="h1" variant="h5" gutterBottom>
        Sign In
      </Typography>
      <SignInForm />
      <SignInGoogle />
      <SignInFacebook />
      <SignInTwitter />
      <PasswordForgetLink />
      <SignUpLink />
    </Paper>
  </Container>
);
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};
const SignInFormBase = ({ firebase, history }) => {
  const [state, setState] = useState({ ...INITIAL_STATE });

  const onSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = state;

    try {
      await firebase.doSignInWithEmailAndPassword(email, password);
      setState({ ...INITIAL_STATE });
      history.push(ROUTES.HOME);
    } catch (error) {
      setState({ ...state, error });
    }
  };

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { email, password, error } = state;
  const isInvalid = password === '' || email === '';

  return (
    <form onSubmit={onSubmit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={onChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={onChange}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={isInvalid}
        style={{ marginTop: '16px' }}
      >
        Sign In
      </Button>
      {error && <Typography color="error">{error.message}</Typography>}
    </form>
  );
};

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }
  onSubmit = event => {
    event.preventDefault();
  
    this.props.firebase
  .doSignInWithGoogle()
  .then(socialAuthUser => {
    const { uid, displayName, email } = socialAuthUser.user;

    // Create a user in your Firebase Realtime Database
    const userRef = this.props.firebase.user(uid);
    set(userRef, {
      username: displayName,
      email: email,
      roles: {},
    });
  })
  .then(() => {
    this.setState({ error: null });
    this.props.history.push(ROUTES.HOME);
  })
  .catch(error => {
    if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
      error.message = ERROR_MSG_ACCOUNT_EXISTS;
    }

    this.setState({ error });
  });

  };
  

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Button type="submit">Sign In with Google</Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Button type="submit">Sign In with Facebook</Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        });
      })
      .then(() => {
        this.setState({ error: null });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <Button type="submit">Sign In with Twitter</Button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withRouter,
  withFirebase,
)(SignInFacebookBase);

const SignInTwitter = compose(
  withRouter,
  withFirebase,
)(SignInTwitterBase);

export default SignInPage;


export { SignInForm, SignInGoogle, SignInFacebook, SignInTwitter };
