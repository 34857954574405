// Carousel.js

import React from "react";

const Carousel = ({ images, selectedThumbnailIndex, onThumbnailClick }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        src={images[selectedThumbnailIndex]}
        alt={`Product ${selectedThumbnailIndex + 1}`}
        style={{
          width: "100%",
          maxHeight: "500px",
          objectFit: "contain",
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "16px",
          overflowX: "auto",
        }}
      >
        {images.map((thumbnail, thumbnailIndex) => (
          <img
            key={thumbnailIndex}
            src={thumbnail}
            alt={`Thumbnail ${thumbnailIndex + 1}`}
            style={{
              width: "80px",
              height: "80px",
              margin: "0 8px",
              cursor: "pointer",
              border:
                thumbnailIndex === selectedThumbnailIndex
                  ? "2px solid blue"
                  : "none",
            }}
            onClick={() => onThumbnailClick(thumbnailIndex)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
