import React, { useState } from 'react';
import { Button, Typography, Paper,LinearProgress, Grid} from '@mui/material';
import { REACT_APP_GENERAL_LLM_URL} from '../Utils/env';

const SEOGrader = ({enrichedProductInfoEnglish}) => {

  const [seoReport, setSEOReport] = useState(null);

  const handleGradeSEO = async () => {
    try {
      const apiUrl = `${REACT_APP_GENERAL_LLM_URL}/seo-grading`;
      const requestBody = {
        model: 'mistral',
        title: enrichedProductInfoEnglish.title,
        description: enrichedProductInfoEnglish.description,
        stream: false
        // Include other SEO-related information in the prompt as needed
      };
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log('result', result);
  
        setSEOReport(result); // Set the entire response object
  
      } else {
        console.error('Failed to grade SEO. HTTP Status:', response.status);
      }
    } catch (error) {
      console.error('Error grading SEO:', error);
    }
  };
  

  // Function to determine color based on grade
  const getColorBasedOnGrade = (grade) => {
    if (grade === 'A') {
      return 'success.main';
    } else if (grade === 'B') {
      return 'info.main';
    } else {
      return 'error.main';
    }
  };
  
  const renderBarGraph = () => {
    if (!seoReport) {
      return <div>Loading...</div>;
    }
  
    const criteriaArray = Object.entries(seoReport.seoGrade || {});
  
    return (
      <div>
        <Typography variant="h6">SEO Criteria Grades</Typography>
        {criteriaArray.map(([criteria, { grade, percentage, explanation, recommendation }]) => (
          <Paper key={criteria} style={{ marginBottom: '20px', padding: '15px' }}>
            <Typography variant="body1">
              {criteria}: {grade} ({percentage}%)
            </Typography>
            <LinearProgress
              variant="determinate"
              value={percentage}
              sx={{ height: 20, backgroundColor: getColorBasedOnGrade(grade) }}
            />
            <Typography variant="body2">Explanation: {explanation}</Typography>
            <Typography variant="body2">Recommendation: {recommendation}</Typography>
          </Paper>
        ))}
  
        <Typography variant="h6">
          Overall SEO Grade: {seoReport.seoGrade.overallSEOGrade.grade} ({seoReport.seoGrade.overallSEOGrade.percentage}%)
        </Typography>
        <LinearProgress
          variant="determinate"
          value={seoReport.seoGrade.overallSEOGrade.percentage}
          sx={{ height: 20, backgroundColor: getColorBasedOnGrade(seoReport.seoGrade.overallSEOGrade.grade), marginBottom: '20px' }}
        />
        <Typography variant="body2">Explanation: {seoReport.seoGrade.overallSEOGrade.explanation}</Typography>
        <Typography variant="body2">Recommendation: {seoReport.seoGrade.overallSEOGrade.recommendation}</Typography>
      </div>
    );
  };
  
  return (
    <div>
      <Typography variant="h4">SEO Grader</Typography>
      <Button variant="contained" onClick={handleGradeSEO}>
        Grade SEO
      </Button>
      {/* Render the bar graph directly */}
      {renderBarGraph()}
    </div>
  );
};

export default SEOGrader;