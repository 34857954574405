import React, { useState } from "react";
import {
  InputBase,
  IconButton,
  Tooltip,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { PhotoCamera, FileUpload, Link } from "@mui/icons-material";

const UploadImage = ({
  onImageUpload,
  uploadedImageSrc,
  fetchImage,
  enrichmentCompleted,
  onReset,
  enrichmentInProgress,
  setEnrichmentInProgress,
  onProductInfoReceived,
  onEnrichmentCompleted
}) => {
  const [urlEntry, setUrlEntry] = useState("");
  const [title, setTitle] = useState("");

  const handleUrlChange = (e) => {
    setUrlEntry(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleUrlSubmit = () => {
    fetchImage(urlEntry);
  };


  const handleEnrichButtonClick = async () => {
    console.log("Enrich button clicked in UploadImage");
    setEnrichmentInProgress(true);
    const llm_url = process.env.REACT_APP_PRODUCT_LLM_URL;
    const tokenUrl = `${llm_url}/token`;
    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_PASSWORD;
    const client_id = process.env.REACT_APP_CLIENT_ID;
    const client_secret = process.env.REACT_APP_CLIENT_SECRET;

    try {
      // Step 1: Request bearer token
      const tokenResponse = await fetch(tokenUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `grant_type=&username=${username}&password=${password}&scope=&client_id=${client_id}&client_secret=${client_secret}`,
      });

      if (!tokenResponse.ok) {
        throw new Error(`Failed to fetch bearer token. Status: ${tokenResponse.status}`);
      }

      const { access_token: accessToken } = await tokenResponse.json();
      console.log("Access token:", accessToken);

      // Step 2: Use bearer token in API request
      let formData = new FormData();
      let response = null;

      if (uploadedImageSrc) {
        const imageUrl = uploadedImageSrc;
        const imageResponse = await fetch(imageUrl);

        if (!imageResponse.ok) {
          throw new Error(`Failed to fetch image. Status: ${imageResponse.status}`);
        }

        const imageBuffer = await imageResponse.arrayBuffer();
        const imageType = imageResponse.headers.get("content-type");

        const imageFile = new File([new Uint8Array(imageBuffer)], "image.jpg", {
          type: imageType,
        });

        formData.append("image", imageFile);
      }

      // Construct prompt and API URL
      let apiUrl = uploadedImageSrc ? `${llm_url}/ask_about_image_qa` : `${llm_url}/ask_about_text`;
      const prompt = uploadedImageSrc ?
        `What is this product? use the provided image and title ${title.trim()} to provide complete google merchant center information including any color, size, material, pattern, features, any other valid variant and marketing information in valid JSON array only like this [{English},{Arabic}]` :
        `What is this product? use the provided title ${title.trim()} to provide complete google merchant center information including any color, size, material, pattern, features, any other valid variant and marketing information in valid JSON array only like this [{English},{Arabic}]`;

      const encodedPrompt = encodeURIComponent(prompt);

      if (uploadedImageSrc) {
        apiUrl = `${apiUrl}?prompt=${encodedPrompt}`;
        response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
          body: formData,
        });
      } else {
        response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `prompt=${encodedPrompt}`,
        });
      }
      if (response.ok) {
        try {
          const jsonResp = await response.json();
          console.log("jsonResp", jsonResp);
          let englishInfo="";
          let arabicInfo="";

          if (jsonResp && Array.isArray(jsonResp.result)) {
            if (jsonResp.result.length === 1){
              const productInfo = jsonResp.result[0];
              console.log("productInfo", productInfo);

              englishInfo = productInfo['English'];
              arabicInfo = productInfo['Arabic'];
            }
            else if (jsonResp.result.length === 2){
              englishInfo = jsonResp.result[0];
              arabicInfo = jsonResp.result[1];
            }

            // Check if English and Arabic product information are available
            if (englishInfo && arabicInfo) {
              // Pass English and Arabic product information to the parent
              onProductInfoReceived({
                EnrichedProductEnglish: JSON.stringify(englishInfo),
                EnrichedProductArabic: JSON.stringify(arabicInfo),
              });
            } else {
              console.error("Missing English or Arabic product information");
            }
          } else {
            console.error("Invalid JSON response format or insufficient data");
          }
        } catch (error) {
          console.error("Error processing JSON response:", error);
        }
      } else {
        console.error("Request failed with status:", response.status);
      }


    } catch (error) {
      console.error("Error during API request:", error);

      if (error.response) {
        console.error("Response data:", error.response.data);
      }
    } finally {
      setEnrichmentInProgress(false);
    }
  };

  return (
    <Paper style={{ padding: "16px" }}>
      <Typography variant="h5">Enter Title And/Or Upload an Image</Typography>
      {/* Title input (conditionally rendered) */}
      <InputBase
        placeholder="Enter Title"
        inputProps={{ "aria-label": "enter title" }}
        value={title}
        onChange={handleTitleChange}
        disabled={enrichmentCompleted}
      />

      {/* Camera input */}
      <label htmlFor="camera-input">
        <Tooltip title="Take a photo with camera">
          <IconButton color="primary" component="span">
            <PhotoCamera />
          </IconButton>
        </Tooltip>
      </label>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => onImageUpload(e.target.files[0])}
        style={{ display: "none" }}
        id="camera-input"
      />

      {/* File input */}
      <label htmlFor="file-input">
        <Tooltip title="Upload from device">
          <IconButton color="primary" component="span">
            <FileUpload />
          </IconButton>
        </Tooltip>
      </label>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => onImageUpload(e.target.files[0])}
        style={{ display: "none" }}
        id="file-input"
      />

      {/* URL input */}
      <InputBase
        placeholder="Paste URL"
        inputProps={{ "aria-label": "paste URL" }}
        value={urlEntry}
        onChange={handleUrlChange}
        disabled={enrichmentCompleted}
      />
      <Tooltip title="Upload from URL">
        <span>
          <IconButton
            color="primary"
            onClick={handleUrlSubmit}
            disabled={enrichmentCompleted || !urlEntry}
          >
            <Link />
          </IconButton>
        </span>
      </Tooltip>

      {/* Uploaded image */}
      {uploadedImageSrc && !enrichmentCompleted && (
        <img
          src={uploadedImageSrc}
          alt="Uploaded Product"
          style={{ width: "100%", marginTop: "16px" }}
        />
      )}

      {/* Enrich button */}
      {!enrichmentCompleted && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleEnrichButtonClick}
          style={{ marginLeft: "8px" }}
          disabled={
            enrichmentInProgress ||
            (!uploadedImageSrc && title.trim() === "") // Disable if neither image nor title is available
          }
        >
          Enrich
        </Button>
      )}

      {/* Loading spinner */}
      {enrichmentInProgress && (
        <CircularProgress size={24} style={{ marginLeft: "8px" }} />
      )}

      {/* Reset button */}
      {enrichmentCompleted && (
        <Button
          variant="outlined"
          color="primary"
          onClick={onReset}
          style={{ marginLeft: "8px" }}
        >
          Reset
        </Button>
      )}
    </Paper>
  );
};

UploadImage.defaultProps = {
  onImageUpload: () => { },
  onUrlSubmit: () => { },
  onReset: () => { },
  uploadedImageSrc: null,
  enrichmentCompleted: false,
  enrichmentInProgress: false,
};


export default UploadImage;
