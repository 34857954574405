import React, { useState, useEffect } from 'react';
import Carousel from './Carousel';
import { makeStyles } from '@mui/styles'; // Import makeStyles
import {
  Tab,
  Tabs,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { REACT_APP_ARABIC_LLM_URL, REACT_APP_ARABIC_LLM_USERNAME, REACT_APP_ARABIC_LLM_PASSWORD, REACT_APP_ARABIC_LLM_CLIENT_ID, REACT_APP_ARABIC_LLM_CLIENT_SECRET } from '../Utils/env';
// Define your styles using makeStyles
const useStyles = makeStyles((theme) => ({

  translateButton: {
    /* Existing styles for the button */
    transition: 'transform 0.3s ease-in-out',
  },
  translateButtonHovered: {
    transform: 'scale(1.1)',
  },
  translateButtonAnimation: {
    animation: '$translateButtonClickAnimation 0.3s ease-in-out',
  },
  // Define the keyframes for the animation
  '@keyframes translateButtonClickAnimation': {
    from: {
      transform: 'scale(1)',
    },
    to: {
      transform: 'scale(1.1)',
    },
  },
}));

const Enrichment = ({
  tabValue,
  enrichedProductInfoEnglish,
  enrichedProductInfoArabic,
  setEnrichedProductInfoArabic,
  setTabValue,
}) => {

  const [dialects, setDialects] = useState([]);
  const [tones, setTones] = useState([]);
  const [parsedProductInfo, setParsedProductInfo] = useState(null);
  const [selectedThumbnailIndex, setSelectedThumbnailIndex] =
    useState(0);
  const [enrichmentInProgress, setEnrichmentInProgress] =
    useState(false);
  const [englishImageUrls, setEnglishImageUrls] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  useState(false);
  const [selectedDialect, setSelectedDialect] = useState('');
  const [selectedTone, setSelectedTone] = useState('');



  const attributeTranslations = {
    title: 'اسم',
    description: 'وصف',
    model: 'موديل',
    brand: 'العلامة التجارية',
    category: 'الفئة',
    price: 'سعر',
    availability: 'في المنتج المخزون',
    gtin: 'جتين',
    condition: 'حالة',
    size: 'مقاس',
    color: 'اللون',
    gender: 'الجنس',
    material: 'المادة',
    pattern: 'نمط',
    season: 'الموسم',
    activity: 'النشاط',
    shipping_weight: 'وزن_الشحن',
    shipping_length: 'طول_الشحن',
    shipping_width: 'عرض_الشحن',
    shipping_height: 'ارتفاع',
    tax_category: 'فئة_الضريبة',
    product_type: 'نوع_المنتج'

    // Add more translations as needed
  };
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [translateDialogOpen, setTranslateDialogOpen] =
    useState(false);

  const handleCheckboxChange = (label) => {
    if (selectedLabels.includes(label)) {
      setSelectedLabels(
        selectedLabels.filter(
          (selectedLabel) => selectedLabel !== label,
        ),
      );
    } else {
      setSelectedLabels([...selectedLabels, label]);
    }
  };

  const handleAttributeCheckboxChange = (attribute) => {
    setSelectedAttributes((prevSelected) =>
      prevSelected.includes(attribute)
        ? prevSelected.filter((selected) => selected !== attribute)
        : [...prevSelected, attribute],
    );
  };

  const searchImagesGoogle = async (
    query,
    color,
    brand,
    description,
  ) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
      const cx = process.env.REACT_APP_CUSTOM_SEARCH_ENGINE_ID;

      if (!apiKey || !cx) {
        console.error(
          'API key or custom search engine ID is missing.',
        );
        return [];
      }

      // Construct the search query with additional attributes
      const fullQuery = `${query} ${color || ''} ${brand || ''} ${description || ''
        }`;
      const apiUrl = `https://www.googleapis.com/customsearch/v1?q=${encodeURIComponent(
        fullQuery,
      )}&key=${apiKey}&cx=${cx}&searchType=image`;

      console.log('API URL:', apiUrl);

      const response = await fetch(apiUrl);

      if (!response.ok) {
        console.error(
          `Google image search failed with status ${response.status}`,
        );
        return [];
      }

      const data = await response.json();
      console.log('Image Data:', data);

      if (data.items && data.items.length > 0) {
        const imageUrls = data.items.map((item) => item.link);
        return imageUrls;
      } else {
        console.error('No image results found');
        return [];
      }
    } catch (error) {
      console.error('Error during Google image search:', error);
      return [];
    }
  };

  const handlePersonliseClick = async () => {
    setTranslateDialogOpen(true);
  }

  const handleTranslateClick = async (
    selectedAttributes,
    selectedDialect,
    selectedTone
  ) => {
    try {
      const tokenResponse = await fetch(`${REACT_APP_ARABIC_LLM_URL}/token`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `grant_type=&username=${REACT_APP_ARABIC_LLM_USERNAME}&password=${REACT_APP_ARABIC_LLM_PASSWORD}&scope=&client_id=${REACT_APP_ARABIC_LLM_CLIENT_ID}&client_secret=${REACT_APP_ARABIC_LLM_CLIENT_SECRET}`,
      });

      const tokenData = await tokenResponse.json();
      const accessToken = tokenData.access_token;
      console.log('accessToken', accessToken);

      const apiUrl = `${REACT_APP_ARABIC_LLM_URL}/ask_about_text`;

      // Create an array of promises for each selected attribute
      console.log("selectedAttributes", selectedAttributes);
      const translationPromises = selectedAttributes.map(async (attribute) => {
        try {
          const text = enrichedProductInfoEnglish[attribute];
          console.log(`Translating attribute: ${attribute}, Text: ${text}`);

          let prompt = `Translate ${text} from English`;
          if (selectedTone) prompt += ` using ${selectedTone}`;
          if (selectedDialect) prompt += ` to ${selectedDialect} Arabic`;

          prompt += ' and return exact translation in JSON format {"translation":""}';
          console.log('prompt', prompt);

          const formData = new FormData();
          formData.append("prompt", prompt);
          const body = `prompt=${encodeURIComponent(prompt)}`;

          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: 'application/json',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: body,
          });

          console.log('After fetch request');

          if (response.ok) {
            const translatedData = await response.json();
            console.log('translatedData', translatedData);

            if (translatedData && translatedData.result) {
              const resultObject = JSON.parse(translatedData.result);
              if (resultObject && resultObject.translation) {
                const translatedAttribute = resultObject.translation;
                console.log('translatedAttribute', translatedAttribute);

                return { attribute, translation: translatedAttribute };

              } else {
                console.error('Translation value not found in resultObject:', resultObject);

              }
            } else {
              console.error('Invalid translatedData:', translatedData);
            }

          } else {
            console.error(
              'Translation request failed:',
              response.statusText
            );
            const errorBody = await response.text();
            console.error('Error body:', errorBody);
            return { attribute, translation: null }; // Handle failed translation
          }
        } catch (error) {
          console.error(`Error translating attribute ${attribute}:`, error);
          return { attribute, translation: null }; // Handle error for this attribute
        }
      });

      // Wait for all promises to resolve
      const translations = await Promise.all(translationPromises);

      // Update the state with the translated data for the Arabic tab
      setEnrichedProductInfoArabic((prevInfo) => ({
        ...prevInfo,
        ...translations.reduce((acc, { attribute, translation }) => {
          if (translation !== null) {
            acc[attribute] = translation;
          }
          return acc;
        }, {}),
      }));
    } catch (error) {
      console.error('Error during translation:', error);
    }
  };


  const handleChangeTab = (_, newValue) => {
    setTabValue(newValue);
  };

  const handleThumbnailClick = (index) => {
    setSelectedThumbnailIndex(index);
  };

  useEffect(() => {
    // Mock API response
    const mockApiResponse = {
      dialects: [
        'SaudiArabia',
        'Egyptian Arabic',
        'Levantine Arabic',
        'Gulf Arabic',
        'Moroccan Arabic',
      ],
      tones: [
        'GenZ',
        'Girls',
        'Boys',
        'Women',
        'Men',
        'Unisex',
        'Melanials',
        'Boomer',
        'GenX',
      ],
    };

    setDialects(mockApiResponse.dialects);
    setTones(mockApiResponse.tones);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (tabValue === 0 && enrichedProductInfoEnglish) {
          const performImageSearch = async (content) => {
            try {
              const contentJson =
                typeof content === 'string'
                  ? JSON.parse(content)
                  : content;

              // Perform Google image search based on the product title, color, brand, and description
              const query = `${contentJson.title || ''} ${contentJson.color || ''
                } ${contentJson.brand || ''} ${contentJson.description || ''
                }`;
              const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
              const cx =
                process.env.REACT_APP_CUSTOM_SEARCH_ENGINE_ID;
              const imageUrls = await searchImagesGoogle(
                query,
                contentJson.color,
                contentJson.brand,
                contentJson.description,
                apiKey,
                cx,
              );
              return imageUrls.length > 0
                ? imageUrls
                : [contentJson.image_link].filter(Boolean);
            } catch (error) {
              console.error('Error during image search:', error);
              return [];
            }
          };

          const englishImageUrls = await performImageSearch(
            enrichedProductInfoEnglish,
          );
          setEnglishImageUrls(englishImageUrls);

          setParsedProductInfo({
            images: englishImageUrls,
            title: enrichedProductInfoEnglish.title || '',
            model: enrichedProductInfoEnglish.mpn || '',
            description: enrichedProductInfoEnglish.description || '',
            brand: enrichedProductInfoEnglish.brand || '',
            category:
              enrichedProductInfoEnglish.google_product_category ||
              '',
            features: [],
            price: enrichedProductInfoEnglish.price || 0.0,
            currency: enrichedProductInfoEnglish.currency || 'SAR',
            condition: enrichedProductInfoEnglish.condition || 'New',
            availability:
              enrichedProductInfoEnglish.availability || 'in_stock',
            product_details: {},
            type: enrichedProductInfoEnglish.product_type || '',
          });

          setEnrichmentInProgress(true);
        }
      } catch (error) {
        console.error('Error during data fetching:', error);
        setEnrichmentInProgress(false);
      }
    };

    fetchData();
  }, [
    tabValue,
    enrichedProductInfoEnglish,
    setParsedProductInfo,
    setEnglishImageUrls,
    setEnrichmentInProgress,
  ]);

  useEffect(() => {
    if (tabValue === 1 && enrichedProductInfoArabic) {
      setParsedProductInfo({
        images: englishImageUrls,
        title: enrichedProductInfoArabic.title || '',
        model: enrichedProductInfoArabic.mpn || '',
        description: enrichedProductInfoArabic.description || '',
        brand: enrichedProductInfoArabic.brand || '',
        category:
          enrichedProductInfoArabic.google_product_category || '',
        features: [],
        price: enrichedProductInfoArabic.price || 0.0,
        currency: enrichedProductInfoArabic.currency || 'ريال سعودي',
        condition: enrichedProductInfoArabic.condition || 'جديد',
        availability:
          enrichedProductInfoArabic.availability || 'في المخزون',
        product_details: {},
        type: enrichedProductInfoArabic.product_type || '',
      });

      setEnrichmentInProgress(true);
    }
  }, [tabValue, enrichedProductInfoArabic]);

  if (!parsedProductInfo) {
    console.log('Rendering Loading...');
    return <div>Loading...</div>;
  }

  return (
    <Grid item xs={6}>
      <Paper
        style={{
          padding: '16px',
          maxHeight: '500px',
          overflowY: 'auto',
        }}
      >
        <Tabs value={tabValue} onChange={handleChangeTab}>
          <Tab label="English" />
          <Tab label="عربي" />
        </Tabs>
        <Grid>
          {parsedProductInfo.images &&
            parsedProductInfo.images.length > 0 && (
              <Carousel
                images={parsedProductInfo.images}
                selectedThumbnailIndex={selectedThumbnailIndex}
                onThumbnailClick={handleThumbnailClick}
              />
            )}
        </Grid>
        <Grid dir="rtl">
          <Paper style={{ padding: '16px' }}>
            {/* Rendering Arabic product information with checkboxes */}
            {tabValue === 1 && enrichedProductInfoArabic && (
              <>
                <div>
                  <h2>معلومات المنتج المفضل </h2>
                </div>
                {Object.entries(enrichedProductInfoArabic).map(([attribute, value]) => (
                  <div key={attribute}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedAttributes.includes(attribute)}
                          onChange={() => handleAttributeCheckboxChange(attribute)}
                        />
                      }
                      label={
                        <strong>
                          {attributeTranslations[attribute]}: {typeof value === 'object' ? JSON.stringify(value) : value}
                        </strong>
                      }
                    />
                  </div>
                ))}

                <Button onClick={handlePersonliseClick} color="primary">
                  Personlise
                </Button>


                {/* Dialog for translation */}
                <Dialog
                  open={translateDialogOpen}
                  onClose={() => setTranslateDialogOpen(false)}
                >
                  <DialogTitle>Personalise</DialogTitle>
                  <DialogContent>
                    <FormControl
                      style={{
                        marginLeft: '20px',
                        marginBottom: '20px',
                      }}
                    >
                      <InputLabel id="dialect-label">
                        اللهجة
                      </InputLabel>
                      <Select
                        labelId="dialect-label"
                        id="dialect-select"
                        value={selectedDialect}
                        onChange={(e) =>
                          setSelectedDialect(e.target.value)
                        }
                      >
                        {dialects.map((dialect) => (
                          <MenuItem key={dialect} value={dialect}>
                            {dialect}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl style={{ marginBottom: '16px' }}>
                      <InputLabel id="tone-label">النغمة</InputLabel>
                      <Select
                        labelId="tone-label"
                        id="tone-select"
                        value={selectedTone}
                        onChange={(e) =>
                          setSelectedTone(e.target.value)
                        }
                      >
                        {tones.map((tone) => (
                          <MenuItem key={tone} value={tone}>
                            {tone}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setTranslateDialogOpen(false)}
                      color="primary"
                    >
                      Close
                    </Button>

                    <Button
                      onClick={() =>
                        handleTranslateClick(
                          selectedAttributes,
                          selectedDialect,
                          selectedTone,
                        )
                      }
                      color="primary"
                    >
                      Personlise
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Paper>
        </Grid>

        <Grid>
          {enrichedProductInfoEnglish && tabValue === 0 && (
            <Grid item xs={12} style={{ marginTop: '16px' }}>
              {/* Display English product information */}
              <div>
                <h2>Enriched Product Information</h2>
                {Object.entries(enrichedProductInfoEnglish).map(
                  ([attribute, value]) => (
                    <div key={attribute}>
                      <strong>{attribute}:</strong>
                      {typeof value === 'object' ? (
                        // Handle the case where the value is an object
                        <div>
                          <strong>Country:</strong> {value.country}, <strong>Rate:</strong> {value.rate}
                        </div>
                      ) : (
                        // Render the value as-is
                        value
                      )}
                    </div>
                  ),
                )}
              </div>
            </Grid>
          )}

        </Grid>

        {enrichmentInProgress && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setTabValue(0)}
            style={{ marginTop: '16px' }}
          >
            Reset
          </Button>
        )}
      </Paper>
    </Grid>
  );
};

export default Enrichment;
