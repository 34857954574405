import { initializeApp } from 'firebase/app';
import {getAuth,GoogleAuthProvider,FacebookAuthProvider,TwitterAuthProvider, onAuthStateChanged, signInWithPopup} from 'firebase/auth';
import { getDatabase, ref, get,orderByChild } from 'firebase/database';

console.log('apiKey',process.env.REACT_APP_API_KEY);
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  apid:process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASURMENT_ID
};

class Firebase {
  constructor() {
    const firebaseApp = initializeApp(firebaseConfig);
    const auth = getAuth(firebaseApp);
    const database = getDatabase(firebaseApp);

    /* Helper */

    this.serverValue = database.ServerValue;
    this.emailAuthProvider = auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = auth;
    this.db = database;

    /* Social Sign In Method Provider */

    this.googleProvider = new GoogleAuthProvider();
    this.facebookProvider = new FacebookAuthProvider();
    this.twitterProvider = new TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = async () =>
    await signInWithPopup(this.auth, this.googleProvider)
  

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
  onAuthStateChanged(this.auth, async authUser => {
    if (authUser) {
      try {
        const userRef = ref(this.db, `users/${authUser.uid}`);
        const snapshot = await get(userRef);
        const dbUser = snapshot.val();

        // default empty roles
        if (!dbUser.roles) {
          dbUser.roles = {};
        }

        // merge auth and db user
        authUser = {
          uid: authUser.uid,
          email: authUser.email,
          emailVerified: authUser.emailVerified,
          providerData: authUser.providerData,
          ...dbUser,
        };

        next(authUser);
      } catch (error) {
        // Handle error
        console.error("Error fetching user data:", error);
      }
    } else {
      fallback();
    }
  });

  // *** User API ***

  user = uid => ref(this.db,`users/${uid}`);

  users = () => ref(this.db, 'users');

  // *** Message API ***

  message = uid => ref(this.db, `messages/${uid}`);

  messages = () => ref(this.db, 'messages');
}

export default Firebase;
