export const REACT_APP_USERNAME = process.env.REACT_APP_USERNAME;
export const REACT_APP_PASSWORD = process.env.REACT_APP_PASSWORD;
export const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const REACT_APP_CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const REACT_APP_PRODUCT_LLM_URL= process.env.REACT_APP_PRODUCT_LLM_URL;
export const REACT_APP_ARABIC_LLM_URL=process.env.REACT_APP_ARABIC_LLM_URL;
export const REACT_APP_GENERAL_LLM_URL=process.env.REACT_APP_GENERAL_LLM_URL;
export const REACT_APP_ARABIC_LLM_USERNAME=process.env.REACT_APP_ARABIC_LLM_USERNAME;
export const REACT_APP_ARABIC_LLM_PASSWORD=process.env.REACT_APP_ARABIC_LLM_PASSWORD
export const REACT_APP_ARABIC_LLM_CLIENT_ID=process.env.REACT_APP_ARABIC_LLM_CLIENT_ID
export const REACT_APP_ARABIC_LLM_CLIENT_SECRET=process.env.REACT_APP_ARABIC_LLM_CLIENT_SECRET
