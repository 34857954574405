import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography } from '@mui/material';
import axios from 'axios';
import { withAuthorization } from '../Session';
import { compose } from 'recompose';
import { withEmailVerification } from '../Session/withEmailVerification';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [socket, setSocket] = useState(null);
  const [downloadLinks, setDownloadLinks] = useState({
    processed: null,
    enriched: null,
    error: null,
  });

  const onDrop = async (acceptedFiles) => {
    try {
      const selectedFile = acceptedFiles[0];
      setFile(selectedFile);

      const formData = new FormData();
      formData.append('file', selectedFile);

      await axios.post('http://localhost:3001/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          fileName: selectedFile.name,
        },
      });
      console.log('File uploaded successfully');
    } catch (error) {
      console.error('Error uploading file:', error);
      // Handle error as needed
    }
  };

  useEffect(() => {
    const newSocket = new WebSocket('ws://localhost:3001');
    setSocket(newSocket);
  
    newSocket.addEventListener('message', (event) => {
      try {
        const data = JSON.parse(event.data);
  
        if (data.type === 'processingComplete') {
          // Update state with download links
          setDownloadLinks({
            processed: data.processedLink,
            enriched: data.enrichedLink,
            error: data.errorLink,
          });
        }
      } catch (error) {
        console.error('Error parsing WebSocket message as JSON:', error);
        // Handle non-JSON message, if needed
      }
    });
  
    return () => {
      newSocket.close();
    };
  }, []);
  

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.csv',
  });

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
  
        if (data.type === 'processingComplete') {
          // Display a notification to the user
          alert(data.message);
  
          // Provide links to download the processed, enriched, and error files
          const processedLink = document.createElement('a');
          processedLink.href = data.processedLink;
          processedLink.download = 'processedFile.csv';
          processedLink.innerText = 'Download Processed File';
  
          const enrichedLink = document.createElement('a');
          enrichedLink.href = data.enrichedLink;
          enrichedLink.download = 'enrichedFile.csv';
          enrichedLink.innerText = 'Download Enriched File';
  
          const errorLink = document.createElement('a');
          errorLink.href = data.errorLink;
          errorLink.download = 'errorFile.csv';
          errorLink.innerText = 'Download Error File';
  
          // Append the links to your HTML or display them in another way
          document.body.appendChild(processedLink);
          document.body.appendChild(enrichedLink);
          document.body.appendChild(errorLink);
        }
      };
    }
  }, [socket]);
  
  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <Typography align="center" gutterBottom>
          Drag & drop an Excel or CSV file here, or click to select one
        </Typography>
      </div>
      {file && (
        <Typography align="center">
          Selected File: {file.name}
        </Typography>
      )}

      {/* Display download links when available */}
      {downloadLinks.processed && (
        <Typography align="center">
          Download Processed File: {' '}
          <a href={downloadLinks.processed} download="processedFile.csv">
            processedFile.csv
          </a>
        </Typography>
      )}
      {downloadLinks.enriched && (
        <Typography align="center">
          Download Enriched File: {' '}
          <a href={downloadLinks.enriched} download="enrichedFile.csv">
            enrichedFile.csv
          </a>
        </Typography>
      )}
      {downloadLinks.error && (
        <Typography align="center">
          Download Error File: {' '}
          <a href={downloadLinks.error} download="errorFile.csv">
            errorFile.csv
          </a>
        </Typography>
      )}
    </div>
  );
};

const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  margin: '20px 0',
};

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(FileUpload);
