import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

const useStyles = makeStyles((customTheme) => ({
  accordion: {
    marginBottom: theme.spacing(2),
  },
  accordionSummary: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginRight: theme.spacing(1),
    width: 100,
    height: 50,
  },
  gradeButton: {
    marginLeft: 'auto',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  gradeButtonVisible: {
    opacity: 1,
  },
  sideWindow: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '300px',
    height: '100%',
    backgroundColor: '#fff',
    borderLeft: '1px solid #ccc',
    overflowY: 'auto',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    zIndex: 1000,
  },
  severityDial: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#e0e0e0',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  severityRed: {
    color: 'red',
  },
  severityAmber: {
    color: 'orange',
  },
  severityGreen: {
    color: 'green',
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
}));

const ChannelMappings = ({
  enrichedProductInfoEnglish,
  isEnrichmentCompleted,
}) => {
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const classes = useStyles();
  const [isGradeButtonVisible, setIsGradeButtonVisible] =
    useState(false);
  const [gradingStatus, setGradingStatus] = useState(null);
  const [isSideWindowVisible, setIsSideWindowVisible] =
    useState(false);
  const sideWindowRef = useRef(null);
  const [productStatus, setProductStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const generatedIdRef = useRef({});
  const [mappedProducts, setMappedProducts] = useState({});
  const [productIds, setProductIds] = useState({});
  const [totalAttributes, setTotalAttributes] = useState(0);
  const [gradeButtonVisibility, setGradeButtonVisibility] = useState({
    gmc: false,
    meta_commerce: false,
    tiktok_shop: false,
    whatsapp_business: false,
  });

  const [sideWindowVisibility, setSideWindowVisibility] = useState({
    gmc: false,
    meta_commerce: false,
    tiktok_shop: false,
    whatsapp_business: false,
  });

  const mapProductToChannel = async (channel) => {
    if (!generatedIdRef.current[channel]) {
      generatedIdRef.current[channel] =
        enrichedProductInfoEnglish.id || generateUniqueId();
    }

    switch (channel) {
      case 'gmc':
        return {
          id: generatedIdRef.current[channel],
          offerId: generatedIdRef.current[channel],
          title: enrichedProductInfoEnglish.title || '',
          description: enrichedProductInfoEnglish.description || '',
          link: enrichedProductInfoEnglish.link || '',
          image_link: enrichedProductInfoEnglish.image_link || '',
          availability: enrichedProductInfoEnglish.availability || '',
          price: enrichedProductInfoEnglish.price || 0,
          currency: enrichedProductInfoEnglish.currency || '',
          brand: enrichedProductInfoEnglish.brand || '',
          gtin: enrichedProductInfoEnglish.gtin || 0,
          mpn: enrichedProductInfoEnglish.mpn || '',
          contentLanguage:
            enrichedProductInfoEnglish.contentLanguage || 'en',
          targetCountry:
            enrichedProductInfoEnglish.targetCountry || 'GB',
          channel:
            enrichedProductInfoEnglish.channel || 'online',
        };
      case 'meta_commerce':
        return {
          product_id: generatedIdRef.current[channel],
          title: enrichedProductInfoEnglish.title || null,
          description: enrichedProductInfoEnglish.description || null,
          product_link: enrichedProductInfoEnglish.link || null,
          image_url: enrichedProductInfoEnglish.image_link || null,
          price: enrichedProductInfoEnglish.price || null,
          currency: enrichedProductInfoEnglish.currency || null,
          brand: enrichedProductInfoEnglish.brand || null,
          gtin: enrichedProductInfoEnglish.gtin || null,
        };
      case 'tiktok_shop':
        return {
          product_id: generatedIdRef.current[channel],
          product_name: enrichedProductInfoEnglish.title || null,
          product_description:
            enrichedProductInfoEnglish.description || null,
          image_url: enrichedProductInfoEnglish.image_link || null,
          price: enrichedProductInfoEnglish.price || null,
          stock: enrichedProductInfoEnglish.availability || null,
        };
      case 'whatsapp_business':
        return {
          title: enrichedProductInfoEnglish.title || null,
          description: enrichedProductInfoEnglish.description || null,
          price: enrichedProductInfoEnglish.price || null,
          currency: enrichedProductInfoEnglish.currency || null,
          image_url: enrichedProductInfoEnglish.image_link || null,
          link: enrichedProductInfoEnglish.link || null,
        };
      default:
        return {};
    }
  };

  const generateUniqueId = () => {
    const randomString = Math.random().toString(36).substring(2, 8);
    const paddedTimestamp = Date.now().toString().padEnd(43, '0');
    const uniqueId = paddedTimestamp + randomString;
    return uniqueId.substring(0, 50);
  };

  const insertProduct = async (product, channel) => {
    try {
      console.log('Insert Products function started');
      if (channel === 'gmc') {
        console.log('insertProduct Product in gmc is ', product);

        const response = await fetch(`${baseUrl}/insertProduct`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(product),
        });
        if (response.ok) {
          const result = await response.json();
          console.log(
            'Insert Products function completed successfully',
            result,
          );

          // Set insertInProgress back to false once the insertion is complete
          return result.productId;
        } else {
          return response.status;
        }
      }
    } catch (error) {
      console.error('Error inserting product:', error);
    }
  };

  useEffect(() => {
    const insertProducts = async () => {
      try {
        console.log('Effect triggered');
        console.log(
          'enrichedProductInfoEnglish:',
          enrichedProductInfoEnglish,
        );

        if (enrichedProductInfoEnglish) {
          const channels = [
            'gmc',
            'meta_commerce',
            'tiktok_shop',
            'whatsapp_business',
          ];
          const updatedProductIds = {};
          const updatedMappedProducts = {};

          for (const channel of channels) {
            const mappedProduct = await mapProductToChannel(channel);

            try {
              await insertProduct(mappedProduct, channel);
              setTotalAttributes(10);
              if (channel === 'gmc') {
                updatedProductIds[
                  channel
                ] = `${mappedProduct.channel}:${mappedProduct.contentLanguage}:${mappedProduct.targetCountry}:${mappedProduct.id}`;
              }

              // Store the mapped product data for each channel
              updatedMappedProducts[channel] = mappedProduct;
            } catch (error) {
              console.error(
                `Error inserting product for ${channel}:`,
                error,
              );
            }
          }

          // Update both state variables
          setProductIds(updatedProductIds);
          setMappedProducts(updatedMappedProducts);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error inserting products:', error);
      }
    };

    insertProducts();
  }, [enrichedProductInfoEnglish]);

  const getSeverityColor = (gradingStatus, channel) => {
    // Determine severity color based on the number of issues
    const issuesCount =
      gradingStatus[0]?.itemLevelIssues?.length || 0;

    if (issuesCount === 0) {
      return 'green';
    } else if (issuesCount <= 50) {
      return 'amber';
    } else {
      return 'red';
    }
  };

  const handleGradingButtonClick = async (channel) => {
    if (channel === 'gmc') {
      console.log('productIds', productIds);
      const productId = productIds[channel];

      setIsGradeButtonVisible(true);
      setGradingStatus('loading');

      try {
        // Instead of calling getProductStatus directly, you can use a separate function
        const status = await fetchProductStatus(productId, channel);

        console.log('status', status);

        // Check if status is not null and has a length before mapping
        if (status && status.length > 0) {
          // Update the gradingStatus state with the fetched data
          setGradingStatus(status);
        } else {
          console.error('Product status is null or empty.');
          setGradingStatus('error');
        }

        setIsGradeButtonVisible(false);
        setIsSideWindowVisible(true);
      } catch (error) {
        console.error('Error getting product status:', error);
        setGradingStatus('error');
      }
    } else {
      console.log(`Grade button clicked for ${channel}`);
    }
  };

  const fetchProductStatus = async (productId, channel) => {
    try {
      if (channel === 'gmc') {
        const response = await fetch(`${baseUrl}/getProductStatus`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ productId }),
        });

        if (!response.ok) {
          console.error(
            `Error getting product status for ${channel}. Status: ${response.status}`,
          );
          return [];
        }

        const result = await response.json();

        if (result && result.data) {
          console.log('result', result.data);
          return result.data;
        } else {
          console.error(
            'Empty or undefined response from getProductStatus',
          );
          return [];
        }
      }
    } catch (error) {
      console.error(
        `Error getting product status for ${channel}:`,
        error,
      );
      return [];
    }
  };

  // Example logic to toggle side window visibility
  const handleAccordionSummaryClick = (channel) => {
    setGradeButtonVisibility((prevVisibility) => ({
      ...prevVisibility,
      [channel]: !prevVisibility[channel],
    }));
    setSideWindowVisibility((prevVisibility) => ({
      ...prevVisibility,
      [channel]: !prevVisibility[channel],
    }));
    // Calculate and set the total number of attributes for the selected channel
    const attributesCount = Object.keys(
      mappedProducts[channel] || {},
    ).length;
    setTotalAttributes(attributesCount);
  };

  return (
    <div>
      {gradingStatus && isSideWindowVisible && (
        <Paper className={classes.sideWindow} ref={sideWindowRef}>
          <IconButton
            className={classes.closeIcon}
            onClick={() => setIsSideWindowVisible(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5">{`Grading Status - ${gradingStatus[0].targetCountry}`}</Typography>
          <div
            className={classes.severityDial}
            style={{
              backgroundColor: getSeverityColor(
                gradingStatus,
                gradingStatus[0].channel,
              ),
            }}
          >
            <div>
              {gradingStatus[0]?.itemLevelIssues ? (
                <>
                  {Math.round(
                    100 -
                      (gradingStatus[0].itemLevelIssues.length /
                        totalAttributes) *
                        100,
                  )}
                  %
                </>
              ) : (
                <>No data available</>
              )}
            </div>
          </div>
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Attribute</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Code</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gradingStatus[0]?.itemLevelIssues?.map(
                  (issue, i) => (
                    <TableRow key={i}>
                      <TableCell>{issue.attributeName}</TableCell>
                      <TableCell>{issue.description}</TableCell>
                      <TableCell>{issue.code}</TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      <div>
        <Paper style={{ padding: '16px', marginTop: '16px' }}>
          <Typography variant="h5">Channel Mappings</Typography>
          {[
            'gmc',
            'meta_commerce',
            'tiktok_shop',
            'whatsapp_business',
          ].map((channel) => (
            <Accordion
              key={channel}
              className={classes.accordion}
              onChange={() => handleAccordionSummaryClick(channel)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.accordionSummary}
              >
                <img
                  src={`/logos/${channel.toLowerCase()}-logo.png`}
                  alt={`${channel} Logo`}
                  className={classes.logo}
                />
                <button
                  className={`${classes.gradeButton} ${
                    gradeButtonVisibility[channel]
                      ? classes.gradeButtonVisible
                      : ''
                  }`}
                  onClick={() => handleGradingButtonClick(channel)}
                >
                  Grade
                </button>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Attribute</TableCell>
                        <TableCell>Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(
                        mappedProducts[channel] || {},
                      ).map(([attribute, value]) => (
                        <TableRow key={attribute}>
                          <TableCell>{attribute}</TableCell>
                          <TableCell>{value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))}
        </Paper>
      </div>
    </div>
  );
};

export default ChannelMappings;
