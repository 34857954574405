import React from 'react';
import { compose } from 'recompose';
import { Typography, Container } from '@mui/material';
import Demo  from './Demo';

import { withAuthorization, withEmailVerification } from '../Session';
import Messages from '../Messages';

const HomePage = () => (
  <Container>
    <Typography variant="h1"></Typography>
    <Demo/>

    {/* <Messages /> */}
  </Container>
);

const condition = (authUser) => !!authUser;

export default compose(withEmailVerification, withAuthorization(condition))(HomePage);
