import React, { useState } from "react";
import { Grid, Paper } from "@mui/material";
import UploadImage from "./UploadImage";
import Enrichment from "./Enrichment";
import ChannelMappings from "./ChannelMappings";
import SEOGrader from "./SEOGrader";

const Demo = () => {
  const [loading, setLoading] = useState(false);
  const [showTranslationButton, setShowTranslationButton] = useState(false);
  const [setEnrichmentCompleted, setSetEnrichmentCompleted] = useState(false);

  const [tabValue, setTabValue] = useState(0); 
  const [uploadedImageSrc, setUploadedImageSrc] = useState(null);
   const [enrichmentInProgress, setEnrichmentInProgress] = useState(false);
  const [productInfo, setProductInfo] = useState(null);
  const [isEnrichmentCompleted, setIsEnrichmentCompleted] = useState(false);
  const [enrichedProductInfoEnglish, setEnrichedProductInfoEnglish] = useState({
  })

  const handleEnrichmentCompleted = () => {
    setIsEnrichmentCompleted(true);
  }
  // Add the state for enrichedProductInfoArabic and its setter function
  const [enrichedProductInfoArabic, setEnrichedProductInfoArabic] = useState({})
;
  
  const handleImageUpload = async (file) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("image", file);

    const uploadUrl = `${process.env.REACT_APP_BACKEND_URL}/images`;
    

    try {
      const response = await fetch(uploadUrl, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const resp = await (response.json());
        console.log('imageUrl',resp.imageUrl);
        setUploadedImageSrc(resp.imageUrl);
        setLoading(false);
        setShowTranslationButton(true); 
      } else {
        console.error("Image upload failed");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during image upload:", error);
      setLoading(false);
    }
  };

  const handleReset = () => {
    // Reset the state and hide the translation button
    setUploadedImageSrc(null);
    setEnrichmentCompleted(false);
    setShowTranslationButton(false);
    // Reset other state values as needed
  };

  const fetchImage = async (imageUrl) => {
    try {
      const imageResponse = await fetch(imageUrl);
      const imageBlob = await imageResponse.blob();

      // Create a File from the Blob with a specific name (e.g., "image.jpg")
      const imageFile = new File([imageBlob], "image.jpg", {
        type: imageBlob.type,
      });
      return imageFile;
    } catch (error) {
      console.error("Error during image fetch:", error);
      throw error; // Propagate the error back to the caller
    }
  };
  // Inside DemoTab.js

  
    const handleProductInfoReceived = (productInfo) => {
      console.log("Received productInfo in DemoTab:", productInfo);
    
      // Update the enriched product information based on the received data
      if (productInfo) {
        // Parse the JSON strings for English and Arabic information
        const enrichedProductEnglish = JSON.parse(
          productInfo.EnrichedProductEnglish || "{}"
        );
    
        const enrichedProductArabic = JSON.parse(
          productInfo.EnrichedProductArabic || "{}"
        );
    
        // Update the state with the parsed information
        setEnrichedProductInfoEnglish((prevInfo) => ({
          ...prevInfo,
          ...enrichedProductEnglish,
        }));
    
        setEnrichedProductInfoArabic((prevInfo) => ({
          ...prevInfo,
          ...enrichedProductArabic,
        }));
      }
    };
    

  console.log("DemoTab Rendered. productInfo:", productInfo);

  return (
    <Grid container spacing={3} style={{ marginTop: "16px" }}>
      <Grid item xs={6}>
        <Paper style={{ padding: "16px" }}>
          <UploadImage
            onImageUpload={handleImageUpload}
            uploadedImageSrc={uploadedImageSrc}
            enrichmentInProgress={enrichmentInProgress}
            onProductInfoReceived={handleProductInfoReceived}
            onReset={handleReset}
            fetchImage={fetchImage}
            setEnrichmentInProgress={setEnrichmentInProgress}
            onEnrichmentCompleted={handleEnrichmentCompleted} 
          />
        </Paper>
      </Grid>

      {setEnrichmentInProgress && (
        <Enrichment
          tabValue={tabValue}
          setTabValue={setTabValue} 
          showTranslationButton={true}
          uploadedImageSrc={uploadedImageSrc}
          onReset={handleReset}
          enrichedProductInfoEnglish={enrichedProductInfoEnglish}
          setEnrichedProductInfoEnglish={setEnrichedProductInfoEnglish}
          enrichedProductInfoArabic={enrichedProductInfoArabic}
          setEnrichedProductInfoArabic={setEnrichedProductInfoArabic}
          isEnrichmentCompleted={isEnrichmentCompleted}
        />
      )}

       {/* Render ChannelMappings if enrichment is completed */}
       {setEnrichmentInProgress && (
        <Grid item xs={12}>
          <ChannelMappings
            enrichedProductInfoEnglish={enrichedProductInfoEnglish}
          />
        </Grid>
      )}
      {/* Render SEOGrader if enrichment is completed */}
      {setEnrichmentInProgress && (
        <Grid item xs={12}>
          <SEOGrader            enrichedProductInfoEnglish={enrichedProductInfoEnglish}

/>
        </Grid>
      )}
    </Grid>

  );
};

export default Demo;
