import React from 'react';
import { Typography, Button, Container, Paper } from '@mui/material';

const Landing = () => (
  <Container maxWidth="sm" style={{ marginTop: '50px' }}>
    <Paper elevation={3} style={{ padding: '20px' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Welcome to Arabi Product Enrichment
      </Typography>
      <Typography variant="body1" paragraph>
        Discover high-quality products enriched with Arabi's unique touch.
      </Typography>
      <Typography variant="body1" paragraph>
        Our commitment is to provide you with the best products that meet your needs.
      </Typography>
      <Button variant="contained" color="primary" href="/products">
        Explore Products
      </Button>
    </Paper>
  </Container>
);

export default Landing;
